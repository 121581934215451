<template>
  <v-container fill-height>
    <v-layout justify-center>
      <v-flex xs12 sm12 md10 lg8 xl6>
        <v-form class="elevation-5">
          <template>
            <v-toolbar color="primary" dark dense>
              <v-icon class="mr-2">mdi-truck-cargo-container</v-icon>
              <v-toolbar-title>{{ tituloForm }}</v-toolbar-title>
              <v-spacer></v-spacer>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="metClear"
                  >
                    mdi-refresh
                  </v-icon>
                </template>
                <span>Refresh</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="metVoltar"
                  >
                    mdi-arrow-left-circle-outline
                  </v-icon>
                </template>
                <span>Voltar</span>
              </v-tooltip>
            </v-toolbar>
          </template>

          <template>
            <v-container fluid>
              <v-row dense>
                <v-col cols="auto" md="2" align-self="center">
                  <v-text-field
                    dense
                    hide-details="auto"
                    v-model="varVeiculoLocal.id"
                    label="ID"
                    readonly
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="auto" md="3" align-self="center">
                  <v-select
                    dense
                    hide-details="auto"
                    v-model="varVeiculoLocal.tipo"
                    label="Tipo"
                    :items="varTipo"
                    outlined
                  ></v-select>
                </v-col>
                <v-col cols="auto" md="2" align-self="center">
                  <v-text-field
                    dense
                    hide-details="auto"
                    v-model="varVeiculoLocal.placa"
                    label="Placa"
                    v-mask="'NNNNNNNNNN'"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="auto" md="3" align-self="center">
                  <v-text-field
                    dense
                    hide-details="auto"
                    v-model="varVeiculoLocal.cor"
                    label="Cor"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="auto" md="2" align-self="center">
                  <v-text-field
                    dense
                    hide-details="auto"
                    v-model="varVeiculoLocal.ano"
                    label="Ano"
                    v-mask="'####'"
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="auto" md="3" align-self="center">
                  <v-text-field
                    dense
                    hide-details="auto"
                    v-model="varVeiculoLocal.marca"
                    label="Marca"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="auto" md="3" align-self="center">
                  <v-text-field
                    dense
                    hide-details="auto"
                    v-model="varVeiculoLocal.modelo"
                    label="Modelo"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="auto" md="3" align-self="center">
                  <v-text-field
                    dense
                    hide-details="auto"
                    v-model="varVeiculoLocal.chassi"
                    label="Chassi"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="auto" md="3" align-self="center">
                  <v-text-field
                    dense
                    hide-details="auto"
                    v-model="varVeiculoLocal.renavam"
                    label="Renavam"
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="auto" md="3" align-self="center">
                  <v-text-field
                    dense
                    hide-details="auto"
                    v-model="varVeiculoLocal.seguro"
                    label="Seguro"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="auto" md="3" align-self="center">
                  <v-text-field
                    dense
                    type="date"
                    hide-details="auto"
                    v-model="varVeiculoLocal.vencimentoSeguro"
                    label="Vencimento"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="auto" md="3" align-self="center">
                  <v-text-field
                    dense
                    hide-details="auto"
                    v-model="varVeiculoLocal.capacidadeTracao"
                    label="Capacidade Tração"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="auto" md="3" align-self="center">
                  <v-text-field
                    dense
                    hide-details="auto"
                    v-model="varVeiculoLocal.tara"
                    label="Tara"
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="auto" md="2" align-self="center">
                  <v-text-field
                    dense
                    type="number"
                    hide-details="auto"
                    v-model="varVeiculoLocal.numeroEixos"
                    label="Nº. Eixos"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="auto" md="5" align-self="center">
                  <v-text-field
                    dense
                    hide-details="auto"
                    v-model="varVeiculoLocal.cidadeEmplacamento"
                    label="Cidade Emplacamento"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="auto" md="2" align-self="center">
                  <!-- <v-text-field
                    dense
                    hide-details="auto"
                    v-model="varVeiculoLocal.uf"
                    label="UF"
                    outlined
                  ></v-text-field> -->
                  <v-autocomplete
                    dense
                    hide-details="auto"
                    v-model="varVeiculoLocal.uf"
                    label="UF"
                    :items="compEstadosSelect"
                    item-text="uf"
                    item-value="uf"
                    outlined
                    clearable
                  ></v-autocomplete>
                </v-col>
                <v-col cols="auto" md="3" align-self="center">
                  <v-autocomplete
                    dense
                    hide-details="auto"
                    v-model="varVeiculoLocal.idCarretaVinculada"
                    label="Carreta Vinculada"
                    :items="compVeiculosSelect"
                    item-text="placa"
                    item-value="id"
                    outlined
                    clearable
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="auto" md="6" align-self="center">
                  <v-autocomplete
                    dense
                    hide-details="auto"
                    v-model="varVeiculoLocal.idProprietario"
                    label="Proprietário"
                    :items="compProprietariosSelect"
                    item-text="nomeFantasia"
                    item-value="id"
                    outlined
                    clearable
                  ></v-autocomplete>
                </v-col>
                <v-col cols="auto" md="6" align-self="center">
                  <v-autocomplete
                    dense
                    hide-details="auto"
                    v-model="varVeiculoLocal.idTransportador"
                    label="Transportador"
                    :items="compTransportadoresSelect"
                    item-text="nomeFantasia"
                    item-value="id"
                    outlined
                    clearable
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="auto" md="6" align-self="center">
                  <v-autocomplete
                    dense
                    hide-details="auto"
                    v-model="varVeiculoLocal.idMotorista"
                    label="Motorista"
                    :items="compMotoristasSelect"
                    item-text="nome"
                    item-value="id"
                    outlined
                    clearable
                  ></v-autocomplete>
                </v-col>
                <v-col cols="auto" md="3" align-self="center">
                  <v-text-field
                    dense
                    hide-details="auto"
                    v-model="varVeiculoLocal.tipoRastreador"
                    label="Tipo Rastreador"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="auto" md="3" align-self="center">
                  <v-text-field
                    dense
                    type="number"
                    hide-details="auto"
                    v-model="varVeiculoLocal.numeroRastreador"
                    label="Número Rastreador"
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <!-- <v-col cols="auto" md="3" align-self="center">
                  <v-radio-group
                    dense
                    v-model="varVeiculoLocal.tipo"
                    row
                    mandatory
                  >
                    <v-radio label="CRT" value="CRT"></v-radio>
                    <v-radio label="MIC" value="MIC"></v-radio>
                  </v-radio-group>
                </v-col> -->
              </v-row>
            </v-container>
          </template>

          <template>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="ml-4 mr-4 mb-4"
                  color="green darken-1"
                  v-bind="attrs"
                  v-on="on"
                  @click="submit"
                >
                  mdi-check-circle
                </v-icon>
              </template>
              <span>Salvar</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="mr-4 mb-4"
                  color="red darken-1"
                  v-bind="attrs"
                  v-on="on"
                  @click="metVoltar"
                >
                  mdi-backspace
                </v-icon>
              </template>
              <span>Cancelar</span>
            </v-tooltip>
          </template>
        </v-form>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import { validationMixin } from "vuelidate";
// import { required, email } from "vuelidate/lib/validators";
import DatasMixin from "@/mixins/DatasMixin";

const { mapState, mapActions } = createNamespacedHelpers("cadVeiculos");

export default {
  mixins: [validationMixin, DatasMixin],
  // components: { tableCompras },
  // validations: {
  //   varVeiculoLocal: {
  //     nome_veiculo: { required },
  //     email: { required, email },
  //     veiculo: { required },
  //     senha: { required },
  //     ativo: { required },
  //   },
  // },

  data: () => ({
    name: "CadVeiculosForm",
    tituloForm: "",
    varVeiculoLocal: {},
    varTipo: ["Trator", "Reboque", "Semi-reboque", "Truck"],
  }),

  computed: {
    ...mapState([
      "staVeiculoSelect",
      "staTransportadoresSelect",
      "staProprietariosSelect",
      "staMotoristasSelect",
      "staVeiculosVincSelect",
      "staEstadosSelect",
    ]),

    compTransportadoresSelect() {
      return this.staTransportadoresSelect;
    },

    compProprietariosSelect() {
      return this.staProprietariosSelect;
    },

    compMotoristasSelect() {
      return this.staMotoristasSelect;
    },

    compVeiculosSelect() {
      return this.staVeiculosVincSelect;
    },

    compEstadosSelect() {
      return this.staEstadosSelect;
    },
  },

  watch: {
    staVeiculoSelect(veiculoNovo) {
      this.metSincronizar(veiculoNovo);
    },
  },

  created() {
    this.metSincronizar(this.staVeiculoSelect);
    if (this.staVeiculoSelect) {
      this.tituloForm = "Editar Veículo";
    } else {
      this.tituloForm = "Novo Veículo";
    }
    this.actTransportadoresSelect(this.$store.state.login.licenca);
    this.actProprietariosSelect(this.$store.state.login.licenca);
    this.actMotoristasSelect(this.$store.state.login.licenca);
    this.actVeiculosSelect(this.$store.state.login.licenca);
    this.actEstadosSelect();
    this.metDatasDB();
  },

  methods: {
    ...mapActions([
      "actSelecionarVeiculo",
      "actResetarVeiculo",
      "actCriarVeiculo",
      "actEditarVeiculo",
      "actResetarErro",
      "actTransportadoresSelect",
      "actProprietariosSelect",
      "actMotoristasSelect",
      "actVeiculosSelect",
      "actEstadosSelect",
    ]),

    metObterTransportador(id) {
      if (id != null && this.staTransportadoresSelect != undefined) {
        const ret = this.staTransportadoresSelect.filter((t) => t.id == id);
        return ret[0].nomeFantasia;
      } else {
        const ret = "";
        return ret;
      }
    },

    metObterProprietario(id) {
      if (id != null && this.staProprietariosSelect != undefined) {
        const ret = this.staProprietariosSelect.filter((p) => p.id == id);
        return ret[0].nomeFantasia;
      } else {
        const ret = "";
        return ret;
      }
    },

    metObterMotorista(id) {
      if (id != null && this.staMotoristasSelect != undefined) {
        const ret = this.staMotoristasSelect.filter((m) => m.id == id);
        return ret[0].nome;
      } else {
        const ret = "";
        return ret;
      }
    },

    selecionarVeiculoEdicao(veiculo) {
      this.actSelecionarVeiculo({ veiculo });
    },

    async salvarVeiculo() {
      switch (this.staVeiculoSelect) {
        case undefined:
          this.varVeiculoLocal.criacao =
            this.$store.state.login.usuario.usuario;
          this.varVeiculoLocal.modificacao =
            this.$store.state.login.usuario.usuario;
          this.varVeiculoLocal.aliasTransportador = this.metObterTransportador(
            this.varVeiculoLocal.idTransportador
          );
          this.varVeiculoLocal.aliasProprietario = this.metObterProprietario(
            this.varVeiculoLocal.idProprietario
          );
          this.varVeiculoLocal.aliasMotorista = this.metObterMotorista(
            this.varVeiculoLocal.idMotorista
          );
          await this.actCriarVeiculo({
            veiculo: this.varVeiculoLocal,
            licenca: this.$store.state.login.licenca,
          });

          if (this.$store.state.cadVeiculos.staErro != undefined) {
            this.$root.snackbar.show({
              type: "danger",
              message: this.$store.state.cadVeiculos.staErro,
            });
            this.actResetarErro();
          } else {
            this.tituloForm = "Editar Veiculo";
            this.$root.snackbar.show({
              type: "success",
              message: "Veiculo Criada!",
            });
          }
          break;
        default:
          this.varVeiculoLocal.modificacao =
            this.$store.state.login.usuario.usuario;
          this.varVeiculoLocal.criado = undefined;
          this.varVeiculoLocal.criacao = undefined;
          this.varVeiculoLocal.modificado = undefined;
          this.varVeiculoLocal.aliasTransportador = this.metObterTransportador(
            this.varVeiculoLocal.idTransportador
          );
          this.varVeiculoLocal.aliasProprietario = this.metObterProprietario(
            this.varVeiculoLocal.idProprietario
          );
          this.varVeiculoLocal.aliasMotorista = this.metObterMotorista(
            this.varVeiculoLocal.idMotorista
          );
          await this.actEditarVeiculo({
            veiculo: this.varVeiculoLocal,
            licenca: this.$store.state.login.licenca,
          });
          // this.formataDatasDB();
          if (this.$store.state.cadVeiculos.staErro != undefined) {
            this.$root.snackbar.show({
              type: "danger",
              message: this.$store.state.cadVeiculos.staErro,
            });
            this.actResetarErro();
          } else {
            this.$root.snackbar.show({
              type: "success",
              message: "Veiculo Salva!",
            });
          }
      }
      //this.resetar();
    },

    metDatasDB() {
      this.varVeiculoLocal.vencimentoSeguro = this.mixDataDBDCal(
        this.varVeiculoLocal.vencimentoSeguro
      );
    },

    metSincronizar(veiculo) {
      this.varVeiculoLocal = Object.assign({}, veiculo || { id: null });
    },
    submit() {
      // this.$v.$touch();
      this.salvarVeiculo();
      // this.$router.back();
    },
    metClear() {
      //this.$v.$reset();
      // this.varVeiculoLocal.ultima_compra = this.staVeiculoSelect.ultima_compra;
      // this.varVeiculoLocal.email = this.staVeiculoSelect.email;
      // this.varVeiculoLocal.veiculo = this.staVeiculoSelect.veiculo;
      // this.varVeiculoLocal.senha = this.staVeiculoSelect.senha;
      // this.varVeiculoLocal.ativo = this.staVeiculoSelect.ativo;
    },
    metVoltar() {
      this.$router.back();
    },
  },
};
</script>
